.list-page {
  padding-top: 64px; /* Match the AdminNav height */
}

.moderation-view-container {
  padding: 2rem;
  max-width: 90%;
  margin: 0 auto;
}

.moderation-view-title {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #333;
}

.moderation-view-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 1.2rem;
  color: #666;
}

.moderation-view-empty {
  text-align: center;
  padding: 3rem;
  background-color: #f5f5f5;
  border-radius: 8px;
  color: #666;
  font-size: 1.1rem;
}

.moderation-recordings-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.moderation-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.moderation-header span {
  margin-left: 0.5rem;
  font-weight: 500;
  color: #666;
}

.moderation-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
  background-color: white;
  border-radius: 8px;
  padding: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.moderation-controls-left {
  display: flex;
  align-items: flex-start;
  padding-left: 0.5rem;
  padding-top: 30px;
  min-width: 40px;
  flex-shrink: 0;
}

.recordingaccordion {
  flex: 1;
  min-width: 0; /* Allows flex item to shrink below content size */
}

.moderation-controls-right {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 30px;
  gap: 0.5rem;
  flex-shrink: 0;
  margin-left: 8px;
}

.content-rating-select {
  width: 130px;
}

.delete-button {
  color: #d32f2f;
  padding: 4px;
  width: 38px;
  height: 38px;
  min-width: unset;
}

.delete-button svg {
  font-size: 24px;
}

.delete-button:hover {
  background-color: rgba(211, 47, 47, 0.04);
}

/* Style the Material-UI components */
.content-rating-select .MuiOutlinedInput-root {
  border-radius: 4px;
}

.content-rating-select .MuiSelect-select {
  padding: 6px 32px 6px 12px;
}

.moderation-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} 