@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --font-lato: Lato;
  --font-montserrat: Montserrat;
  --font-raleway: Raleway;
  --font-inter: Inter;
  --font-work-sans: "Work Sans";
  --font-manrope: Manrope;

  /* font sizes */
  --font-size-base: 16px;
  --font-size-xs: 12px;
  --font-size-6xl: 25px;
  --font-size-xl: 20px;
  --font-size-21xl: 40px;
  --font-size-3xs: 10px;
  --font-size-11xl: 30px;
  --font-size-lg: 18px;

  /* Colors */
  --white: #F5F7FA;
  --brand-primary-primary-dark: #0277bd;
  --color-darkslategray-100: #444;
  --color-darkslategray-200: #3d4143;
  --color-darkslategray: #333;
  --brand-primary-primary: #03a9f4;
  --black: #000;
  --color-dimgray: #666;
  --color-dimgray-100: #555;
  --fof-eggshell: #f1f1ef;
  --color-lightgray: #ccc;
  --fof-cobalt: #236df6;
  --color-gray-400: #fafafa;
  --color-gray-100: #807373;
  --color-gray-500: #282828;
  --color-gray-200: #060000;
  --color-gray-300: #020000;
  --color-darkslateblue-100: #3d3c6a;
  --color-darkslateblue-200: #001d46;
  --color-gainsboro: #d9d9d9;
  --color-lightsteelblue: #bcc6d6;

  /* Gaps */
  --gap-2xl: 21px;
  --gap-mid: 17px;
  --gap-9xs: 4px;
  --gap-29xl: 48px;
  --gap-base: 16px;
  --gap-5xs: 8px;
  --gap-12xs: 1px;
  --gap-3xs: 10px;
  --gap-3xl: 22px;
  --gap-lgi: 19px;
  --gap-smi: 13px;
  --gap-lg: 18px;
  --gap-9xl: 28px;
  --gap-16xl: 35px;

  /* Paddings */
  --padding-2xs: 11px;
  --padding-sm: 14px;
  --padding-25xl: 44px;
  --padding-5xs: 8px;
  --padding-35xl: 54px;
  --padding-9xs: 4px;
  --padding-10xl: 29px;
  --padding-lgi: 19px;
  --padding-59xl: 78px;
  --padding-8xs: 5px;
  --padding-3xs: 10px;
  --padding-base: 16px;
  --padding-11xs: 2px;
  --padding-2xl: 21px;
  --padding-16xl: 35px;
  --padding-19xl: 38px;
  --padding-5xl: 24px;
  --padding-259xl: 278px;
  --padding-7xl: 26px;
  --padding-6xl: 25px;
  --padding-xl: 20px;
  --padding-4xl: 23px;
  --padding-11xl: 30px;
  --padding-3xl: 22px;
  --padding-126xl: 145px;
  --padding-81xl: 100px;
  --padding-21xl: 40px;
  --padding-24xl: 43px;
  --padding-31xl: 50px;
  --padding-358xl: 377px;
  --padding-smi: 13px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-8xs: 5px;
  --br-81xl: 100px;
  --br-mini: 15px;
  --br-6xs: 7px;
  --br-lgi: 19px;
}