.author-claim-process {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .author-claim-process h2 {
    color: #333;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .author-claim-process p {
    color: #666;
    font-size: 16px;
    margin-bottom: 25px;
  }
  
  .author-option {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 15px;
    align-items: center;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    padding: 15px;
    margin-bottom: 15px;
    transition: background-color 0.3s ease;
  }
  
  .author-option:hover {
    background-color: #f0f0f0;
  }
  
  .author-option input[type="checkbox"] {
    transform: scale(1.2);
  }
  
  .author-option label {
    font-size: 16px;
    color: #333;
  }
  
  button {
    background-color: #0066cc;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 30px;
  }
  
  button:hover {
    background-color: #0052a3;
  }
  
  .radio-option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .radio-option input[type="radio"] {
    margin-right: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #0066cc;
    border-radius: 50%;
    outline: none;
    transition: all 0.2s ease-in-out;
  }
  
  .radio-option input[type="radio"]:checked {
    background-color: #0066cc;
    box-shadow: inset 0 0 0 4px #fff;
  }
  
  .radio-option label {
    font-size: 16px;
    color: #333;
    display: flex;
    align-items: center;
  }
  
  .radio-option img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-left: 10px;
    border-radius: 4px;
  }
  
  .custom-website-input {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .custom-website-input input[type="text"] {
    margin-left: 10px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  @media (max-width: 768px) {
    .author-claim-process {
      padding: 20px;
    }
  
    .author-claim-process h2 {
      font-size: 24px;
    }
  
    .author-claim-process p {
      font-size: 14px;
    }
  
    .author-option {
      gap: 12px;
      padding: 12px;
    }
  
    .author-option label {
      font-size: 14px;
    }
  
    button {
      font-size: 14px;
      padding: 10px 20px;
    }
  }