.author-dashboard {
    padding: 20px;
    background-color: #f5f5f5;
}

.dashboard-title {
    margin-bottom: 20px;
    color: #333;
}

.dashboard-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.dashboard-section {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    height: 100%;
}

.section-title {
    margin-bottom: 16px;
    color: #444;
}

.author-profile-section,
.recordings-section,
.pins-section {
    flex: 1;
    min-width: 300px;
}

.author-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.author-profile-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.author-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 16px;
    border: 3px solid #f0f0f0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.author-name {
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
}

.author-bio {
    margin-bottom: 12px;
    color: #555;
    line-height: 1.4;
}

.author-website {
    margin-bottom: 16px;
    color: #666;
}

.author-website a {
    color: #1976d2;
    text-decoration: none;
}

.author-website a:hover {
    text-decoration: underline;
}

.author-tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 16px;
}

.author-tag {
    background-color: #e0e0e0;
    color: #333;
    padding: 4px 8px;
    margin: 4px;
    border-radius: 16px;
    font-size: 0.8rem;
    display: inline-block;
}

.edit-button {
    margin-top: 16px;
}

.recordings-list {
    padding: 0;
}

.recording-item {
    padding: 0 !important;
    margin-bottom: 12px;
}

.recording-item-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #ffffff;
    transition: box-shadow 0.3s ease;
}

.recording-item-inner:hover {
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.recording-item-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 10px;
}

.recording-title {
    flex-grow: 1;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
}

.audio-player-container {
    flex-shrink: 0;
}

.audio-player-container .MuiIconButton-root {
    padding: 8px;
}

.audio-player-container .MuiSvgIcon-root {
    font-size: 24px;
}

.recording-details {
    padding-inline: 20px;
    font-size: 0.9em;
    color: #666;
}

.recording-detail-item {
    margin-bottom: 4px;
}

.recording-detail-label {
    font-weight: bold;
    margin-right: 5px;
}

.map-container {
    height: 400px;
}

#authorMap {
    height: 100%;
    width: 100%;
    border-radius: 8px;
}

.no-recordings {
    text-align: center;
    color: #888;
}

.author-map {
    height: 400px;
    width: 100%;
    border-radius: 8px;
}

/* Responsive adjustments */
@media (max-width: 960px) {
    .author-dashboard {
        padding: 10px;
    }

    .dashboard-section {
        padding: 15px;
    }
}

.author-edit-form {
    padding: 20px;
}

.author-edit-form .MuiTextField-root {
    margin-bottom: 16px;
}

.author-image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}

.author-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.author-edit-form .MuiButton-root {
    margin-top: 8px;
}

/* Add these new styles */
.edit-buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 20px;
}

.edit-buttons-container .MuiButton-root {
    min-width: 100px;
}

/* If you want to ensure the primary button (Save) stands out more */
.edit-buttons-container .MuiButton-containedPrimary {
    margin-left: 16px;
}

.author-dashboard .dashboard-section.author-profile {
    position: relative;
    padding-bottom: 60px; /* Adjust this value as needed */
}

.author-dashboard .dashboard-section.author-profile.editing-mode {
    padding-bottom: 100px; /* Increase padding in edit mode */
}

.author-dashboard .edit-mode-container {
    display: flex;
    flex-direction: column;
    align-self: stretch;
}

.author-dashboard .edit-buttons-container {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    display: flex;
    justify-content: space-between;
}

.author-dashboard .edit-buttons-container button {
    min-width: 100px; /* Adjust as needed */
}
