.list-header {
    display: grid;
    align-self: stretch;
    background-color: var(--neutral-white);
    box-sizing: border-box;
    font-size: var(--font-size-xl);
    color: var(--black);
    font-family: var(--font-inter);
    padding: 10px;
    align-items: center;
}

.header-item {
    flex: 1;
    position: relative;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    text-decoration: none;
    font-size: small;
}