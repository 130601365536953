.accordion {
  width: 100%;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-xl);
  font-family: var(--font-inter);
  border-bottom: 2px solid #000000;
  
  /* z-index: 1000; */
  overflow: visible; /* Add this line */
}

.icon {
  width: 80px;
}

.accordion-header,
.messages-accordion-header,
.wander-accordion-header,
.user-accordion-header,
.loading {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  /* Adjust the number of columns as needed */
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  background-color: #1E90FF;
  color: white;
  padding: var(--padding-4xl) var(--padding-5xs);
  height: 130px;

}
.wander-accordion-header {
  grid-template-columns: repeat(3, 1fr);
}
.messages-accordion-header {
  grid-template-columns: repeat(7, 1fr);
}

.user-accordion-header {
  grid-template-columns: repeat(4, 1fr);
}

.loading {
  grid-template-columns: repeat(1, 1fr);
  border-bottom: 2px solid #000000;
}

/* Add these media queries */
@media (max-width: 960px) {
  .accordion-header {
    grid-template-columns: repeat(7, 1fr);
  }

  .accordion-header > *:nth-child(8),
  .accordion-header > *:nth-child(9) {
    display: none;
  }

  .icon {
    width: 60px;
  }
}

@media (max-width: 600px) {
  .accordion-header {
    grid-template-columns: repeat(5, 1fr);
    height: 100px;
  }

  .accordion-header > *:nth-child(6),
  .accordion-header > *:nth-child(7) {
    display: none;
  }

  .icon {
    display: none;
  }

  .headingdata {
    font-size: 14px;
  }
}

.headingdata {
  display: flex;
  text-align: center;
  position: relative;
  /* flex-shrink: 0; */
  flex: 1;
}

.drag-container {
  width: 56px;
  height: 46px;
  flex-direction: column;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.burger-icon,
.check-icon {
  align-self: center;
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  align-items: center;
  justify-content: center;
  justify-self: center;
  /* Centers horizontally in the grid cell */
  align-self: center;
}

.pin-key {
  align-self: center;
  flex: 1;
  max-width: 100px;
  overflow: hidden;
  max-height: 110px;
  align-items: center;
  justify-content: center;

}

.content-container {
  align-self: stretch;
  flex-direction: row;
  flex: 1;
  border-radius: var(--br-mini);
  border: 1px solid var(--black);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  margin: 10px;
  gap: 15px;
}

.pin-information {
  background-color: var(--color-darkgray);
  height: 100%;
  padding: var(--padding-3xs) var(--padding-base) var(--padding-base);
  box-sizing: border-box;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 7px solid #1E90FF;
  color: black;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.recordings-container {
  width: 100%;
  border: 2px solid black;
  padding: 15px;
  /* margin-top: 20px; */
  border-radius: 8px;
}

.next-row-details {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: var(--gap-5xs);
  text-align: center;
}

.edit-container6 {
  display: flex;
  align-self: stretch;
  flex: 1;
}

.pinlabel {
  display: flex;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  flex: 1;
}

.chevron-container {
  display: flex;
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.chevron-stroke-icon {
  width: '100%';
}

.chevron-rotated {
  transform: rotate(180deg);
}

.top-row {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-14xl);
}

.venue-logo-11 {
  position: relative;
  width: 116px;
  height: 102px;
  object-fit: cover;
}

.album-select1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-37xl);
}

.tags-label {
  position: relative;
  font-weight: 700;
  flex-shrink: 0;
  align-self: center;
}

.tags {
  margin-top: 10px;

}

.accordion .tag{ 
  color: black;
  font-size: 12px;
}
.button-add-new-recording {
  align-self: stretch;
  flex: 1;
  cursor: pointer;
  margin-top: 15px;
}

.button-container5 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: var(--padding-smi) var(--padding-59xl) var(--padding-smi) var(--padding-1082xl);
  box-sizing: border-box;
  margin-top: 15px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjust the height as needed */
  color: #1E90FF; 
}

.recording-heading {
  font-size: 16px;
  font-weight: 700;
  color: #1E90FF;
  margin-bottom: 10px;
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
.recording-container {
  width: 100%;

}
.drop-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 255, 0, 0.2); /* Light green overlay */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .drag-over {
    background-color: #6bd40a; /* Change color when dragged over */
  }

  .no-recordings {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; /* Ensure it spans the full width of its container */
    height: 100px; /* Adjust as needed */
    text-align: center;
    color: #000000; /* Changed to black for better visibility */
    font-size: 16px; /* Adjust font size as needed */
  }

.userKeydata {
  font-size: 14px; /* Adjust font size as needed */
  color: #333; /* Set a color for the text */
  margin-left: 10px; /* Add some spacing from the previous element */
  font-weight: 500; /* Adjust font weight as needed */
  overflow: hidden; /* Handle overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  white-space: nowrap; /* Prevent text from wrapping */
}

.checkbox-container {
  display: flex;
  align-items: center; /* Vertically center the items */
  justify-content: center !important; /* Horizontally center the items */
  gap: 5px; /* Adjust spacing between the checkbox and label */
  color: white; /* Ensure text color matches the design */
}

.word-buttons {
  display: none;
  position: absolute;
  top: -15px; /* Move buttons up slightly */
  left: 50%;
  transform: translateX(-50%);
  gap: 5px;
}

.editable-word-container:hover .word-buttons,
.editable-word-container.editing .word-buttons {
  display: flex;
}

.word-buttons .MuiIconButton-root {
  padding: 4px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  min-width: unset;
  box-shadow: none;
}

.word-buttons .MuiIconButton-root:nth-child(1) {
  background-color: #1E90FF; /* Light blue background for add button */
}

.word-buttons .MuiIconButton-root:nth-child(2) {
  background-color: #1560AB; /* Darker blue background for remove button */
}

.word-buttons .MuiSvgIcon-root {
  font-size: 16px;
  color: white;
}

/* Override any default styles that might be causing the issue */
.word-buttons .MuiIconButton-root:hover {
  background-color: inherit;
}

.word-buttons .MuiTouchRipple-root {
  display: none;
}

.transcription-section {
  line-height: 2.5; /* Increased line height for more space between lines */
  border: 2px solid #1E90FF; /* Add a border with the same color as the accordion header */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Add some internal padding */
  margin-top: 20px; /* Add some space above the transcription section */
  background-color: #adadad; /* Light background color */
}

.transcription-section h3 {
  color: #000000; /* Match the color to the accordion theme */
  margin-top: 0; /* Remove top margin from the title */
  margin-bottom: 15px; /* Add some space below the title */
  font-size: 18px; /* Adjust font size as needed */
}

.transcription-instructions {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
  line-height: 1.4;
}

.editable-word-container {
  position: relative;
  display: inline-block;
  margin-right: 5px; /* Space between words */
  margin-bottom: 5px; /* Space below words */
}

.edit-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.edit-controls input {
  flex-grow: 1;
  padding: 5px;
  border-radius: 4px;
}

.edit-controls button {
  padding: 5px 10px;
  background-color: #1E90FF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100px;
}

.edit-controls button:hover {
  background-color: #1560AB;
}
