.branch-view {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.branch-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 20px;
}

.branch-header {
  margin-bottom: 20px;
}



.bottom-nav {
  margin-top: auto;
  padding: 20px;
}

/* Debug styles to make sure content is visible */
.branching-container {
  background-color: white;
}

.branch-level {
  margin-bottom: 20px;
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.level-pins {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.pin-wrapper {
  flex: 1;
  min-width: 250px;
  max-width: 350px;
} 