.org-dashboard {
  min-height: 100vh;
  background-color: var(--color-whitesmoke);
}

.org-dashboard .MuiPaper-root {
  min-height: 500px;
}

.org-dashboard .MuiTabs-root {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--color-gray-200);
}

.org-dashboard .MuiTab-root {
  text-transform: none;
  font-weight: 500;
}

.org-dashboard .MuiList-root {
  background-color: white;
  border-radius: 4px;
}

.org-dashboard .MuiListItem-root {
  border-bottom: 1px solid var(--color-gray-100);
}

.org-dashboard .MuiListItem-root:last-child {
  border-bottom: none;
}

.org-dashboard .MuiChip-root {
  text-transform: capitalize;
}

.org-dashboard .error-message {
  margin-top: 16px;
  color: var(--color-error);
  text-align: center;
} 