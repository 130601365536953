.button-row-container {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

.button-row-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px; /* Set a fixed height for the entire row */
}

.left-section,
.center-section,
.right-section {
  display: flex;
  align-items: center;
  height: 100%;
}

.settings-icon {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle {
  height: 100%;
  display: flex;
}

.view-button {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid var(--black);
  font-size: 0.9rem; /* Slightly reduced font size */
  padding: 10px 20px; /* Add padding to increase space around the text */
  margin: 0;
  white-space: nowrap; /* Prevent text wrapping */
  min-width: 80px; /* Ensure minimum width for buttons */
}

.view-button.left {
  border-radius: 4px 0 0 4px;
}

.view-button.middle {
  border-radius: 0;
  border-left: none;
  border-right: none;
}

.view-button.right {
  border-radius: 0 4px 4px 0;
}

.view-button.active {
  background-color: #0066cc;
  color: white;
}

.view-button.inactive {
  background-color: var(--color-darkslategray);
  color: white;
}

.view-button.middle:last-child {
  border-radius: 0 4px 4px 0;
  border-right: 1px solid var(--black);
}

.button-action-submit {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #0099ff;
  color: white;
  font-size: 0.9rem;
  padding: 0 1rem;
  white-space: nowrap;
}

.location {
  height: 100%;
}

.location-input {
  height: 100%;
  padding: 0 0.5rem;
  border: 1px solid var(--black);
  border-radius: 4px;
  font-size: 0.9rem;
}

/* Ensure all elements have the same height */
.settings-icon,
.view-button,
.location-input {
  height: 40px;
  line-height: 40px;
}

/* Modal styles */
.settings-modal {
  padding: 30px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.setting-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.invite-link-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.invite-link-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.invite-link-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.copy-button {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.copy-button:hover {
  background-color: #45a049;
}

.copy-button:active {
  background-color: #3e8e41;
}

.close-button {
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 10px;
  background-color: 'black';
}

@media (max-width: 768px) {
  .button-row-grid {
    flex-direction: column;
    height: auto;
    gap: 1rem;
  }

  .left-section,
  .center-section,
  .right-section {
    width: 100%;
    justify-content: center;
  }

  .toggle,
  .button-action-submit,
  .location {
    width: 100%;
  }
}