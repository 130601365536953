.branching-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
}

.branch-level {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.level-label {
  font-weight: bold;
  color: #666;
}

.level-pins {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.pin-wrapper {
  flex: 1;
  min-width: 200px;
  max-width: 300px;
  cursor: pointer;
}

.level-0 {
  /* Root level styling */
  margin-left: 0;
}

.level-1 {
  /* First level styling */
  margin-left: 2rem;
}

.level-2 {
  /* Second level styling */
  margin-left: 4rem;
}
