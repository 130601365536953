.registration-screen {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-whitesmoke);
}

.registration-screen form {
  width: 100%;
}

.registration-screen .MuiTextField-root {
  margin-bottom: 16px;
}

.registration-screen .MuiButton-root {
  margin-top: 24px;
  height: 48px;
}

.registration-screen .MuiTypography-h4 {
  color: var(--color-gray-900);
  margin-bottom: 32px;
}

.registration-screen .MuiTypography-h6 {
  color: var(--color-gray-700);
}

.registration-screen .error-message {
  color: var(--color-error);
  margin-top: 16px;
  text-align: center;
} 