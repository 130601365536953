.header, 
.admin-message-list-header,
.project-list-header,
.transit-pin-header,
.transit-recording-header  {
  align-self: stretch;
  background-color: var(--neutral-white);
  display: grid;
  grid-template-columns: repeat(9, 1fr); /* Adjust the number of columns as needed */
  flex-direction: row;
  box-sizing: border-box;
  font-size: var(--font-size-xl);
  color: var(--black);
  font-family: var(--font-inter);
  padding: 10px;
  align-items: center;
}
.admin-message-list-header {
  grid-template-columns: repeat(7, 1fr); 
}

.project-list-header {
  grid-template-columns: repeat(6, 1fr); 
}

.transit-pin-header {
  grid-template-columns: repeat(8, 1fr); 
}

.transit-recording-header {
  grid-template-columns: repeat(5, 1fr); 
}

.list-page {
  position: relative;
  background-color: var(--neutral-white);
  width: 100%;
  height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: 18px;
  color: var(--neutral-white);
  font-family: var(--font-raleway);
}

.first-accordion .accordion-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden; /* Add this line */
}

.last-accordion .accordion-header {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  overflow: hidden; /* Add this line */
}

.last-accordion .accordion {
  border-bottom: none;
}

.dragstyles {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow: hidden;
}

.dragstyles.dragging-over {
  background-color: #6bd40a; /* Bright color for high visibility when dragging over */
  position: relative; /* Needed for positioning the pseudo-element */
}

.dragstyles.indented {
  margin-left: 20px !important;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.dragstyles.double-indented {
  margin-left: 40px !important;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

/* New styles for split view */
.split-view {
  display: flex;
  width: 100%;
  height: 100%;
}

.pin-column {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the top */
  
}

.pin-column.transit-project {
  border-right: 1px solid #ccc; /* Add border for transit projects */
}

.recording-column {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the top */
  border-left: 1px solid #ccc; /* Keep this border for all cases */
}

.empty-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 1.2rem;
  color: var(--black);
}

.transit-button-action-submit {
  cursor: pointer;
  border: 1px solid var(--brand-primary-primary-dark);
  padding: 1.5%;
  background-color: var(--brand-primary-primary);
  border-radius: var(--br-5xs);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 200px;
  height: 50%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  /* margin-right: 150px; */
}

.add-new-button {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 20px;
  font-family: var(--font-lato);
  color: var(--white);
  text-align: center;

}

.search-bar {
    min-width: 200px;
    width: 500px;
    margin-left: 50px;
    align-self: flex-start;
}

.search-and-features-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px; /* Increased gap from AdminButtonRow */
    margin-bottom: 20px;
    padding: 0 20px; /* Add some horizontal padding */
}

.search-bar {
    flex: 1;
    margin-right: 20px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.global-features {
    display: flex;
    align-items: center;
}

.global-features-title {
    margin-right: 15px;
    font-weight: bold;
    font-size: 16px;
}

.global-features .MuiFormControlLabel-root {
    margin-left: 15px;
}

.global-features .MuiFormControlLabel-label {
    font-size: 14px;
}

.global-features .MuiSwitch-root {
    margin-right: 5px;
}

.dragstyles.has-indented-child {
  border-bottom-left-radius: 8px;
}

.indented-level-0 {
  margin-left: 0px;
}

.indented-level-1 {
  margin-left: 20px;
}

.indented-level-2 {
  margin-left: 40px;
}

.indented-level-3 {
  margin-left: 60px;
}

/* Add more levels as needed */
