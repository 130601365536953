.author-information {
  align-self: stretch;
  position: relative;
  text-decoration: underline;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  height: 29px;
  flex-shrink: 0;
}
.artist-image {
  position: relative;
  width: 75px;
  height: 68px;
  object-fit: cover;
}
.button-action-submit23 {
  flex: 1;
}
.image-uploader {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xl);
}
.name-input-container {
  border: 0;
  background-color: transparent;
  align-self: stretch;
}
.author-tags {
  align-self: stretch;
  position: relative;
  color: inherit;
  display: inline-block;
  height: 29px;
  flex-shrink: 0;
  text-decoration: none;
}
.author-information-container,
.author-tags-input-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.author-tags-input-container {
  height: 81px;
  gap: var(--gap-3xs);
}
.author-information-container {
  gap: var(--gap-lg);
  text-align: left;
  font-size: var(--font-size-6xl);
  color: var(--black);
  font-family: var(--font-inter);
}
