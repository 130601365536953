.form-builder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    font-size: 1.5rem;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
}
.title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}
.elements {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
}

.button-action {
    cursor: pointer;
    border: none;
    padding: 12px 24px;
    background-color: #007bff; /* Blue color */
    color: white;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    width: 230px;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    margin: 10px 0;
}
.labels {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1rem 0.5rem;
    width: 100%;
    align-items: center;
}

.labels label {
    display: contents;
    font-size: 1.2rem;
}

.labels input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin: 0;
    justify-self: end;
}

.labels span {
    /* This will contain the label text */
    padding-left: 0.5rem;
}

.password-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 1rem;
}

.password-input label {
    margin-bottom: 0.5rem;
}

.password-input input {
    width: 100%;
    padding: 8px;
    font-size: 1rem;
}

.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 2rem;
}