.superadmin-text,
.projectadmin-text {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--font-lato);
  text-align: center;
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.superadmin-text.active-text,
.projectadmin-text.active-text {
  color: var(--white); /* White text */
}

.superadmin-button,
.projectadmin-button {
  background-color: var(--color-darkslategray);
  color: #fff;
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
  padding: 0 var(--padding-6xl);
  border-radius: var(--br-8xs) 0 0 var(--br-8xs);
  overflow: hidden;
  flex-direction: row;
  min-height: 30px;
}

.superadmin-button.active,
.projectadmin-button.active {
  background-color: #0066cc;
}

.projectadmin-button {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid var(--black);
  padding: 0 var(--padding-6xl);
  flex: 1;
  border-radius: 0 var(--br-8xs) var(--br-8xs) 0;
  overflow: hidden;
  flex-direction: row;
}

.button {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 76px;
  flex-direction: row;
  padding: var(--padding-3xs) 0;
  box-sizing: border-box;
}

.forgot-password {
  font-size: var(--font-size-base);
  font-family: var(--font-manrope);
  flex: 1;
  position: relative;
  color: var(--white);
  text-align: right;
  cursor: pointer; /* Add this line to ensure the text itself also shows the pointer */
}

.forgot-password-container {
  text-decoration: none;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer; /* Add this line to change the cursor */
}

.login-text {
  font-size: var(--font-size-base);
  font-family: var(--font-manrope);
  color: var(--color-darkslateblue-200);
  text-align: center;
}

.login-button {
  align-self: stretch;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 0;
  padding: 0 var(--padding-31xl);
  background-color: var(--white);
  border-radius: var(--br-6xs);
  height: 50px;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.login-form {
  align-self: stretch;
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start; /* Change from center to flex-start */
  padding-right : var(--padding-21xl) var(--padding-24xl);
  gap: var(--gap-16xl);
  position: relative;
}

.signUp-text {
  font-size: var(--font-size-base);
  font-family: var(--font-manrope);
  color: var(--color-neutral100);
  text-align: center;
}

.login-error {
  color: #ff0000;
  font-size: var(--font-size-sm);
  font-family: var(--font-manrope);
  text-align: center;
  padding: var(--padding-xs);
  background-color: rgba(255, 0, 0, 0.1);
  border-radius: var(--br-8xs);
  width: 100%;
}

.error-container {
  position: relative; /* Change from absolute to relative */
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: var(--gap-16xl); /* Add margin to separate from other elements */
}

@media screen and (max-width: 420px) {
  .login-form {
    flex: unset;
    align-self: stretch;
  }
}

@media screen and (max-width: 768px) {
  .superadmin-text,
  .projectadmin-text,
  .forgot-password,
  .login-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .superadmin-text,
  .projectadmin-text,
  .forgot-password,
  .login-text {
    font-size: 10px;
    line-height: 10px;
  }

  .button {
    height: 60px;
  }

  .login-button {
    height: 40px;
  }
}
