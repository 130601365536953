.invite-landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f0f8ff; /* Light blue background */
  font-family: Arial, sans-serif;
}

.invite-landing-content {
  background-color: white;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.overhear-logo {
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overhear-logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

h1 {
  color: #0066cc; /* Dark blue */
  text-align: center;
  margin-bottom: 20px;
}

p {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

button {
  background-color: #0066cc;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

button:hover {
  background-color: #0052a3; /* Darker blue on hover */
}

.toggle-auth {
  margin-top: 15px;
  text-align: center;
  width: 100%;
}

.toggle-auth button {
  background-color: transparent;
  color: #0066cc;
  text-decoration: underline;
  padding: 0;
  width: auto;
}

.toggle-auth button:hover {
  color: #0052a3;
}

.app-download {
  text-align: center;
  margin-top: 20px;
}

.app-download a {
  display: inline-block;
  margin: 10px;
}

.app-download img {
  height: 40px;
}

.error-message {
  color: #cc0000;
  text-align: center;
  margin-bottom: 15px;
  width: 100%;
}

.loading-message {
  color: #0066cc;
  text-align: center;
  font-size: 18px;
}

.invite-landing-container button {
  background-color: #0066cc;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

.invite-landing-container button:hover {
  background-color: #0052a3; /* Darker blue on hover */
}