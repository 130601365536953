.recordingaccordion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--color-white);
  font-family: var(--font-inter);
}

.recording-accordion-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; /* Add this to vertically center items */
  background-color: var(--color-primary);
  color: white;
  align-self: stretch;
  background-color: var(--fof-cobalt);
  padding: var(--padding-4xl) var(--padding-5xs);
  border-bottom: 2px solid #000000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.drag-container {
  width: 56px;
  height: 46px;
  flex-direction: column;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.headingdata {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  flex: 1;
}

/* New styles for AudioPlayer */
.audio-player-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px; /* Add some margin for spacing */
}

/* Adjust the size of the play/pause button */
.audio-player-container .MuiIconButton-root {
  padding: 8px;
}

.audio-player-container .MuiSvgIcon-root {
  font-size: 45px;
}

.chevron-container {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  flex: 1;
}

.chevron-stroke-icon {
  position: relative;
  width: 51px;
  height: 10px;
  transition: transform 0.3s ease;
  /* Add transition */
}

.rotated {
  transform: rotate(180deg);
  /* Rotate when open */
}

.edit-container {
  align-self: stretch;
  flex: 1;
}

.author-image-icon {
  align-self: stretch;
  position: relative;
  max-height: 100%;
  width: 83px;
  object-fit: cover;
}

.photo-container {
  border-radius: 10px;
  width: 83px;
  height: 80px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.content-container {
  align-self: stretch;
  flex-direction: row;
  flex: 1;
  border-radius: var(--br-mini);
  border: 1px solid var(--black);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 10px;
  margin: 10px;
  background-color: var(--color-background-light);
  /* Light background for the content */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
}

.details {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: var(--gap-5xs);
  text-align: center;
  margin-bottom: 10px;
}

.recording-next-row-details {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: var(--gap-5xs);
  text-align: center;
  padding-left: 90px;
  margin-bottom: 20px;
}

.tags-label {
  position: relative;
  font-weight: 700;
  flex-shrink: 0;
}

.tags {
  display: flex;
  align-self: stretch;
  height: 40.5px;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
  gap: var(--gap-30xl);
  font-size: var(--font-size-xs);
  font-family: var(--font-raleway);
}

.tag {
  display: flex;
  align-items: center;
  /* This will vertically center the text */
  justify-content: center;
  border: 2px solid black;
  border-radius: 4px;
  padding: 5px;

}

.recording-information {
  align-self: stretch;
  background-color: var(--color-lightgray);
  padding: var(--padding-5xs) var(--padding-5xs) var(--padding-9xs);
  gap: 5px;
  color: black;
  border: 4px solid var(--fof-cobalt);
  /* Adjust color and width as needed */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.first-recording .recording-accordion-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.last-recording .recording-accordion-header {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom: none;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

/* New styles for word-edit section */
.word-edit-section {
  max-height: 200px; /* Adjust the height as needed */
  overflow-y: auto;
  overflow-x: visible; /* Ensure the buttons are visible outside the container */
  border: 1px solid #ccc;
  padding: 20px 10px 10px; /* Add padding to the top */
  position: relative;
}

.editable-word-container {
  display: inline-block;
  position: relative;
}

.editable-word {
  margin: 0 5px;
  transition: color 0.3s, margin-right 0.3s; /* Smooth transition for color and margin */
}

.editable-word:hover {
  color: white; /* Change word color to white on hover */
}

.word-buttons {
  display: none;
  position: absolute;
  top: -30px; /* Position above the word */
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px;
  z-index: 1;
}

.editable-word-container:hover .word-buttons {
  display: flex;
}

/* Styles for the alternatives section */
.alternatives-section {
  display: none; /* Hidden by default */
  position: absolute;
  top: -60px; /* Position above the word buttons */
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  z-index: 2;
  width: 200px; /* Adjust as needed */
}

.alternatives-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.alternative-item {
  padding: 2px 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.alternative-item:hover {
  background-color: #f0f0f0;
}

/* Show alternatives when word is selected */
.editable-word-container.selected .alternatives-section {
  display: block;
}

/* Adjust word buttons position when alternatives are shown */
.editable-word-container.selected .word-buttons {
  top: -90px; /* Move buttons below alternatives */
}
