.popup-portal {
    background-color: var(--white);
    width: 527px;
    height: calc(100vh - 64px);
    flex-direction: column;
    justify-content: stretch;
    padding: var(--padding-2xs) var(--padding-sm);
    box-sizing: border-box;
    text-align: left;
    font-size: var(--font-size-6xl);
    color: var(--black);
    font-family: var(--font-inter);
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 64px;
    overflow-y: auto;
  }
  
  .heading1 {
    align-self: stretch;
    position: relative;
    margin: 0;
    font-size: var(--font-size-21xl);
    font-weight: 400;
    font-family: inherit;
  }
  
  .heading-2 {
    position: relative;
    align-self: stretch;
    text-decoration: underline;
    font-weight: 700;
    color: inherit;
  }
  
  .input-container {
    border: 0;
    background-color: transparent;
    align-self: stretch;
    display: flex;
  
    gap: var(--gap-mid);
    justify-content: flex-start;
  }
  
  .toggle {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    font-size: var(--font-size-xl);
  }
  
  .toggle-on2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    gap: var(--gap-base);
  }
  
  .qr-codes1 {
    position: absolute;
    margin: 0 !important;
    top: 0;
    left: 128px;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    text-decoration: none;
    z-index: 1;
  }
  
  .listbox-component2 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: var(--padding-9xs) 0;
    font-size: var(--font-size-base);
    color: var(--color-dimgray);
    font-family: var(--font-montserrat);
  }
  
  .heading-31 {
    align-self: stretch;
    position: relative;
  }
  
  .lat-long1 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-29xl);
  }
  
  .long-input-container1 {
    border: 0;
    background-color: transparent;
    flex: 1;
  }
  
  .location-tags1 {
    align-self: stretch;
    position: relative;
    color: inherit;
    text-decoration: none;
  }
  
  
  .button-action-cancel,
  .button-action-save,
  .button-action-delete {
    cursor: pointer;
    border: 1px solid var(--color-steelblue);
    padding: var(--padding-5xs) var(--padding-35xl);
    background-color: var(--color-darkslategray-200);
    border-radius: var(--br-5xs);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    width: 116px;
    height: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .button-action-save {
    background-color: var(--color-deepskyblue);
  }
  
  .button-action-delete {
    background-color: rgba(252, 3, 57);
    align-self: stretch;
    display: flex;
    width: 100%;
    margin-top: 20px;
  }
  
  .cancel-text {
    line-height: 24px;
    font-family: var(--font-lato);
    color: var(--white);
    text-align: left;
    position: relative;
    font-size: var(--font-size-base);
  }
  
  .save-text {
    line-height: 24px;
    font-family: var(--font-lato);
    color: var(--black);
    text-align: left;
    position: relative;
    font-size: var(--font-size-base);
  }
  
  .buttons {
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-end;
    gap: var(--gap-2xl);
    display: flex;
    align-items: flex-end;
  }
  
  .pinicon{
    border-radius: 10px;
    width: 80px;
  }
  .tag-input-container {
    border: 0;
    background-color: transparent;
    align-self: stretch;
    margin-bottom: 40px;
  }
  .tag-chip-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
    margin-bottom: 40px;
  }
  .image-uploader {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-9xl);
  }

  .check-toggle {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    gap: var(--gap-base);
  }

  