.orphanedRecordings {
  border-width: 1px;
  border-color: #000;
  border-style: solid;
  background-color: rgb(123, 128, 252);
  border-radius: 10px;
  cursor: grab;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.list-item {
  display: flex;
  padding: 10px;
  font-size: 25px;
  justify-content: center;
  align-items: center;
}

