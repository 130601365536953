.edit-recording {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xl) var(--padding-base);
  gap: var(--gap-3xl);
  font-size: var(--font-size-21xl);
  color: var(--black);
  font-family: var(--font-inter);
  margin-top: 64px;
  max-height: calc(100vh - 64px);
  overflow-y: auto;
}

.edit-recording.edit-recording-pin {
  height: calc(100vh - 64px);
}

.edit-recording.form-view {
  width: 100%;
}
.edit-wander {
  background-color: var(--white);
  width: 501px;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xl) var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-3xl);
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--black);
  font-family: var(--font-inter);
  margin-top: 64px;
  overflow-y: auto;
}
.input-container {
  border: 0;
  background-color: transparent;
  align-self: stretch;
  display: flex;
  gap: var(--gap-mid);
  justify-content: flex-start;
}

.subject-tags1 {
  position: relative;
  display: inline-block;
  width: 462px;
  flex-shrink: 0;
}

.tag-input-container1 {
  border: 0;
  background-color: transparent;
  align-self: stretch;
  flex: 1;
}


.buttons {
  align-self: stretch;
  flex: 1;
  flex-direction: row;

}

.button-action-cancel,
.button-action-save,
.button-action-delete {
  cursor: pointer;
  border: 1px solid var(--color-steelblue);
  padding: var(--padding-5xs) var(--padding-35xl);
  background-color: var(--color-darkslategray-200);
  border-radius: var(--br-5xs);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  width: 116px;
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button-action-save {
  background-color: var(--color-deepskyblue);
}
.button-action-delete {
  background-color: rgba(252, 3, 57);
  align-self: stretch;
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.edit-recording1 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-family: inherit;
}
.recording-information1 {
  align-self: stretch;
  position: relative;
  text-decoration: underline;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  height: 29px;
  flex-shrink: 0;
}
.file-upload {
  align-self: stretch;
  flex: 1;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  display: flex;
  font-family: var(--font-lato);
  text-align: center;
  align-items: center;
  justify-content: center;
}
.artistimage{
  border-radius: 10px;
  width: 80px;
}

.mp3-button.selected,
.url-button.selected {
  background-color: var(--color-gray-500);
  color: var(--white);
}
.mp3-button {
  flex: 1;
  justify-content: center;
  cursor: pointer;
  border: 1px solid var(--black);
  padding: 0 var(--padding-6xl);
  background-color: var(--color-gray-400);
  height: 61px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.url {
  flex: 1;
  justify-content: center;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  display: flex;
  font-family: var(--font-lato);
  
  text-align: center;
  align-items: center;
}
.url-button {
  flex: 1;
  justify-content: center;
  cursor: pointer;
  border: 1px solid var(--black);
  padding: 0 var(--padding-6xl);
  background-color: var(--color-gray-400);
  box-sizing: border-box;
  height: 61px;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.fileurl1 {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  border-radius: 4px;
}

.mp3-button,
.url-button,
.record-button {
  flex: 1;
  justify-content: center;
  cursor: pointer;
  border: 1px solid var(--black);
  padding: 0 var(--padding-6xl);
  background-color: var(--color-gray-400);
  height: 61px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.mp3-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.url-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.record-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.mp3-button.selected,
.url-button.selected,
.record-button.selected {
  background-color: var(--color-gray-500);
  color: var(--white);
}

.mp3-button:not(.selected),
.url-button:not(.selected),
.record-button:not(.selected) {
  color: var(--black);
}

.save {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--font-lato);
  color: var(--black);
  text-align: left;
}
.cancel {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--font-lato);
  color: var(--white);
  text-align: left;
}

.recording-information-container {
  align-self: stretch;
  display: flex; 
  flex-direction: column;
  gap: var(--gap-mid);
  justify-content: flex-start;
  font-size: var(--font-size-6xl);
  margin-top: 90px;
}

.recording-upload-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: var(--gap-mid);
  align-items: center;
  justify-content: flex-start;
}

.recording-info-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: var(--gap-mid);
  align-items: center;
  justify-content: flex-start;
}

.tag-input-container {
  border: 0;
  background-color: transparent;
  align-self: stretch;
  margin-bottom: 40px;
}
.tag-chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 40px;
}

.thank-you-message {
  margin-top: 40px;
  font-size: 1.5rem;
  font-weight: bold;
}
.url-instructions { 
  margin-top: 10px; 
  font-size: 14px; 
  color: gray; 
  text-align: center;
}