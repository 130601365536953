.loginscreen {
  background-color: var(--color-darkslateblue-100);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
}

.login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
  width: 100%;
  margin-top: 5vh; /* Adjust this value to position the content from the top */
}

.logo-container-icon {
  max-width: 100%;
  height: auto;
}

.form-wrapper {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .login-content {
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .loginscreen {
    padding: 1rem;
  }
}

.account-exists-notification {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
  border-radius: 4px;
}