.branching-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  overflow-x: auto;
}

.branch-level {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.level-label {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 15px;
}

.branch-pins {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 10px;
  min-width: fit-content;
}

.branch-pin-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.branching-pin-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.card-content {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.pin-icon {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 4px;
}

.pin-details {
  flex: 1;
}

.pin-name {
  margin: 0;
  font-size: 1rem;
  color: #333;
}

.branch-badge {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.875rem;
  color: white;
  margin-top: 0.5rem;
}

.branch-details {
  display: flex;
  gap: 10px;
  align-items: center;
}

.branch-connector {
  width: 2px;
  height: 20px;
  background-color: #ccc;
  margin-bottom: -10px;
}

.drag-over {
  background-color: rgba(30, 144, 255, 0.1);
  transform: scale(1.02);
}

.loading {
  text-align: center;
  color: #666;
  padding: 1rem;
}
