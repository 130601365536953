.tags-view {
    position: relative;
    background-color: var(--neutral-white);
    width: 100%;
    height: 100vh;
    flex-direction: column;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */

    text-align: center;
    font-size: var(--font-size-11xl);
    color: var(--neutral-white);
    font-family: var(--font-raleway);
}
.title {
    height: 70px;
    display: flex;
    align-items: center;
    padding: var(--padding-3xs) var(--padding-16xl);
    box-sizing: border-box;
    color: var(--black);
    font-family: var(--font-inter);
    margin-top: 50px;
}
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* or any other value depending on your layout */
  }
.project-tags {
    display: flex;
    flex-direction: column;
    align-items: center; 
    gap: 20px;
    justify-content: space-between;
    margin-inline: 390px;
    margin-top: 50px;
}

/* Assuming TagInputField renders an input element, you might need to adjust this selector */
.tag-input-field {
    border: 0;
    background-color: transparent;
    flex-direction: column;
    align-self: center;
    display: flex;
    gap: var(--gap-mid);
    justify-content: center;
    flex-grow: 0; /* Prevent the field from growing */
    margin-right: 20px; /* Add some space between the field and the select, if needed */
}
.chip-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;/* Allow chips to wrap to the next line */
    margin-top: 8px; /* Add some space between the input field and the chips */
}
.project-content-tags {
    align-self: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
}