.password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
    max-width: 400px;
    margin: 2rem auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.password-container label {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 0.5rem;
}

.password-container button {
    min-width: 120px;
    height: 40px;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
}

.password-container button:hover:not(:disabled) {
    background-color: #1565c0;
}

.password-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    outline: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Loading state styles */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Error message styles */
.error-message {
    color: #d32f2f;
    margin: 0.5rem 0;
    font-size: 0.875rem;
}

/* Success message styles */
.success-message {
    color: #2e7d32;
    margin: 0.5rem 0;
    font-size: 0.875rem;
}

/* Form field styles */
.MuiTextField-root {
    width: 100%;
    margin: 0.5rem 0;
}

/* Snackbar custom styles */
.MuiSnackbar-root {
    z-index: 1400;
}

.MuiAlert-root {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.signup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.signup-overlay > * {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
}