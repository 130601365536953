.artists-view {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.author-group {
  margin-bottom: 16px;
}

.group-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.group-header input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 4px;
}

.author-list {
  padding: 16px;
}

.author-item {
  border-bottom: 1px solid #eee;
  padding: 16px 0;
}

.author-item:last-child {
  border-bottom: none;
}

.author-content {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}

.author-content input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-top: 20px; /* Align with author image */
}

.author-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.author-details {
  flex-grow: 1;
}

.author-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.tag {
  background-color: #e0e0e0;
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 0.875rem;
}

.merge-actions {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid #eee;
  margin-top: 16px;
} 