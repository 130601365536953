.auth-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto; /* Allow scrolling */
  }
  
  .auth-modal {
    background-color: grey;
    padding: 20px;
    border-radius: 8px;
    max-height: 80vh; /* Limit the height */
    overflow-y: auto; /* Enable vertical scrolling */
    width: 90%;
    max-width: 500px;
    width: 100%;
    position: relative;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .error-message {
    color: red;
  }