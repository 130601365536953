.terms-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .terms-container h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .terms-text {
    max-height: 300px;
    overflow-y: auto;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.6;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  
  .checkbox-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: flex-start; 
  }
  
  .checkbox-container input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .checkbox-container label {
    font-size: 14px;
    color: #333;
  }
  
  .continue-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .continue-button:hover {
    background-color: #45a049;
  }
  
  .continue-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .signature-image {
    max-width: 200px;
    margin-top: 20px;
  }
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
  
  .checkbox-wrapper label {
    font-size: 1rem;
    flex: 1;
  }
  
  /* Material-UI Checkbox styling */
  .terms-checkbox.MuiCheckbox-root {
    width: 32px;
    height: 32px;
    color: black;
    margin-right: 12px;
  }
  
  .terms-checkbox.MuiCheckbox-root.Mui-checked {
    color: black;
  }
  
  .terms-checkbox .MuiSvgIcon-root {
    font-size: 28px;
  }