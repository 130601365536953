/* src/components/ProjectItem.css */
.project-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-xl);
    color: var(--color-white);
    font-family: var(--font-inter);
    border-bottom: 2px solid #000000;

  }
  
  .project-header {
    display: flex;
    flex-direction: row;
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* Adjust the number of columns as needed */
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    background-color: grey;
    padding: var(--padding-4xl) var(--padding-5xs);
  }
  

  .project-icon {
    align-self: center;
    flex: 1 1;
    max-width: 80px;
    overflow: hidden;
    max-height: 110px;
    align-items: center;
    justify-self: center; /* Centers horizontally in the grid cell */

  }
  

  
  .project-name {
    flex-shrink: 0;
    text-align: center;
    
  }
  
  .project-subject-tags {
    display: flex;
    flex-direction: row;
    justify-self: center; /* Centers horizontally in the grid cell */
    align-self: center; 
    gap: 10px;
    align-self: center;
    width: 210px;
    
    overflow-x: auto; 
    /* overflow-x: auto; */ /* Horizontal scrolling */

    /* Optional: add padding or margin if needed */
    padding: 5px;
    margin: 5px;

    /* Optional: style the scrollbar */
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: black;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: black;
    }
    }
  
  .tag {
    background-color: var(--color-darkgray);
    border: 1px solid var(--black);
    font-size: 10px;
    color: white;
    margin-top: 0px important!;
    margin-inline: 0px;
    align-self: center; 
    
  }
  
.check-icon {
    align-self: center;
    flex: 1 1;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    align-items: center;
    justify-content: center;
}
.edit-container {
  align-self: stretch;
  margin-right: 30px;
  flex: 0;
}

.pin-count {
  margin: 0;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  /* display: flex; */
  color: white;
  justify-content: center;
  align-self: stretch;
  position: relative;
  justify-self: center; /* Centers horizontally in the grid cell */
  align-self: center; 
}